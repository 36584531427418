import { render, staticRenderFns } from "./buttonDark.vue?vue&type=template&id=187fb76d"
var script = {}
import style0 from "./buttonDark.vue?vue&type=style&index=0&id=187fb76d&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsLongArrow: require('/home/node/app/components/icons/long_arrow.vue').default})
