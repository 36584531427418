import { render, staticRenderFns } from "./HeaderElement.vue?vue&type=template&id=28df757d&scoped=true"
import script from "./HeaderElement.vue?vue&type=script&lang=js"
export * from "./HeaderElement.vue?vue&type=script&lang=js"
import style0 from "./HeaderElement.vue?vue&type=style&index=0&id=28df757d&prod&lang=css"
import style1 from "./HeaderElement.vue?vue&type=style&index=1&id=28df757d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28df757d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerNavigationItem: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationItem.js').default,ControllerNavigationBar: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationBar.js').default,UiButtonDark: require('/home/node/app/components/ui/buttonDark.vue').default,EditorLink: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorLink.js').default,ControllerHeader: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerHeader.js').default})
