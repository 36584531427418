
export default {
  data() {
    return {
      options: [
        {
          name: 'title',
          label: 'Naziv linka',
          type: 'text',
        },
      ],
      modeSwitch: 'Light',
      valueMode: '',
    }
  },

  beforeMount() {
    const cookieExist = this.$cookies.get('modeSet')
    if (cookieExist) {
      this.modeSwitch = cookieExist
    }
  },

  mounted() {
    const getCookie = this.$cookies.get('modeSet')
    const element = document.getElementById('__nuxt')
    if (getCookie && getCookie === 'Dark') {
      element.classList.add('dark-mode')
    } else {
      element.classList.remove('dark-mode')
    }
  },

  created() {
    const popupCookie = this.$getCookie('modeSet')
    if (popupCookie && popupCookie === 'Dark') {
      return (this.valueMode = 'Dark')
    } else {
      this.valueMode = 'Light'
    }
  },

  watch: {
    modeSwitch(newVal, oldVal) {
      this.modeSwitch = newVal
      const element = document.getElementById('__nuxt')
      if (newVal === 'Dark' || oldVal === 'Light') {
        element.classList.add('dark-mode')
      } else {
        element.classList.remove('dark-mode')
      }
      this.$setCookie('modeSet', this.modeSwitch)
    },
  },
  methods: {
    switchMode() {
      const element = document.getElementById('__nuxt')
      element.classList.add('dark-mode')
    },
  },
}
