
export default {
  data() {
    return {
      menuOpen: false,
      languageOpen: false,
      modalOpen: true,
      categoriesOpen: false,
    }
  },
  computed: {
    showIntroModal() {
      let show = true
      if (this.$cookies.get('introModal')) {
        show = false
      }
      return show
    },
  },
  methods: {
    closeModal() {
      this.modalOpen = false
      this.$cookies.set('introModal', true)
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    },
    toggleLanguage() {
      this.languageOpen = !this.languageOpen
    },
    languageSwitch(switchLanguage, $i18n) {
      switchLanguage($i18n)
      this.languageOpen = !this.languageOpen
    },

    overflowRemove() {
      const el = document.body
      if (this.menuOpen === true) {
        el.classList.add('overflow-hide')
        el.classList.remove('overflow-y-scroll')
      } else {
        el.classList.remove('overflow-hide')
      }
    },

    toggleCategories(item) {
      if (item.linkV2 === true) {
        this.menuOpen = !this.menuOpen
      }
    },
  },
}
