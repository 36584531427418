export const ControllerBundleCustomization = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerBundleCustomization.js' /* webpackChunkName: "components/controller-bundle-customization" */).then(c => wrapFunctional(c.default || c))
export const ControllerBundles = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerBundles.js' /* webpackChunkName: "components/controller-bundles" */).then(c => wrapFunctional(c.default || c))
export const ControllerCalendar = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCalendar.js' /* webpackChunkName: "components/controller-calendar" */).then(c => wrapFunctional(c.default || c))
export const ControllerCart = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCart.js' /* webpackChunkName: "components/controller-cart" */).then(c => wrapFunctional(c.default || c))
export const ControllerCategory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCategory.js' /* webpackChunkName: "components/controller-category" */).then(c => wrapFunctional(c.default || c))
export const ControllerCommentList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCommentList.js' /* webpackChunkName: "components/controller-comment-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerConfirmEmail = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerConfirmEmail.js' /* webpackChunkName: "components/controller-confirm-email" */).then(c => wrapFunctional(c.default || c))
export const ControllerContactForm = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerContactForm.js' /* webpackChunkName: "components/controller-contact-form" */).then(c => wrapFunctional(c.default || c))
export const ControllerCookie = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCookie.js' /* webpackChunkName: "components/controller-cookie" */).then(c => wrapFunctional(c.default || c))
export const ControllerCrossSell = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCrossSell.js' /* webpackChunkName: "components/controller-cross-sell" */).then(c => wrapFunctional(c.default || c))
export const ControllerCustomForm = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCustomForm.js' /* webpackChunkName: "components/controller-custom-form" */).then(c => wrapFunctional(c.default || c))
export const ControllerError = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerError.js' /* webpackChunkName: "components/controller-error" */).then(c => wrapFunctional(c.default || c))
export const ControllerEvent = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerEvent.js' /* webpackChunkName: "components/controller-event" */).then(c => wrapFunctional(c.default || c))
export const ControllerFeaturedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerFeaturedProducts.js' /* webpackChunkName: "components/controller-featured-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerFooter = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerFooter.js' /* webpackChunkName: "components/controller-footer" */).then(c => wrapFunctional(c.default || c))
export const ControllerHeader = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerHeader.js' /* webpackChunkName: "components/controller-header" */).then(c => wrapFunctional(c.default || c))
export const ControllerLayeredNavigation = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerLayeredNavigation.js' /* webpackChunkName: "components/controller-layered-navigation" */).then(c => wrapFunctional(c.default || c))
export const ControllerNavigationBar = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationBar.js' /* webpackChunkName: "components/controller-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const ControllerNavigationItem = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationItem.js' /* webpackChunkName: "components/controller-navigation-item" */).then(c => wrapFunctional(c.default || c))
export const ControllerNewsletter = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNewsletter.js' /* webpackChunkName: "components/controller-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ControllerPost = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPost.js' /* webpackChunkName: "components/controller-post" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostCategory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategory.js' /* webpackChunkName: "components/controller-post-category" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostCategoryList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategoryList.js' /* webpackChunkName: "components/controller-post-category-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostList.js' /* webpackChunkName: "components/controller-post-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostReview = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostReview.js' /* webpackChunkName: "components/controller-post-review" */).then(c => wrapFunctional(c.default || c))
export const ControllerProduct = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProduct.js' /* webpackChunkName: "components/controller-product" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductCategories = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductCategories.js' /* webpackChunkName: "components/controller-product-categories" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductConfiguration = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductConfiguration.js' /* webpackChunkName: "components/controller-product-configuration" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductCustomOptions = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductCustomOptions.js' /* webpackChunkName: "components/controller-product-custom-options" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductList.js' /* webpackChunkName: "components/controller-product-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductListContainer = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductListContainer.js' /* webpackChunkName: "components/controller-product-list-container" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductsListFiltered = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductsListFiltered.js' /* webpackChunkName: "components/controller-products-list-filtered" */).then(c => wrapFunctional(c.default || c))
export const ControllerPublicUpload = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPublicUpload.js' /* webpackChunkName: "components/controller-public-upload" */).then(c => wrapFunctional(c.default || c))
export const ControllerRecentlyViewedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerRecentlyViewedProducts.js' /* webpackChunkName: "components/controller-recently-viewed-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerRelatedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerRelatedProducts.js' /* webpackChunkName: "components/controller-related-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerSearch = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerSearch.js' /* webpackChunkName: "components/controller-search" */).then(c => wrapFunctional(c.default || c))
export const ControllerUpSell = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerUpSell.js' /* webpackChunkName: "components/controller-up-sell" */).then(c => wrapFunctional(c.default || c))
export const ControllerUserComment = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerUserComment.js' /* webpackChunkName: "components/controller-user-comment" */).then(c => wrapFunctional(c.default || c))
export const ControllerAddress = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerAddress.js' /* webpackChunkName: "components/controller-address" */).then(c => wrapFunctional(c.default || c))
export const ControllerPayment = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerPayment.js' /* webpackChunkName: "components/controller-payment" */).then(c => wrapFunctional(c.default || c))
export const ControllerShipping = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerShipping.js' /* webpackChunkName: "components/controller-shipping" */).then(c => wrapFunctional(c.default || c))
export const ControllerChangePassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerChangePassword.js' /* webpackChunkName: "components/controller-change-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerForgotPassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerForgotPassword.js' /* webpackChunkName: "components/controller-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerLogin = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerLogin.js' /* webpackChunkName: "components/controller-login" */).then(c => wrapFunctional(c.default || c))
export const ControllerOrderHistory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerOrderHistory.js' /* webpackChunkName: "components/controller-order-history" */).then(c => wrapFunctional(c.default || c))
export const ControllerRegister = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerRegister.js' /* webpackChunkName: "components/controller-register" */).then(c => wrapFunctional(c.default || c))
export const ControllerResetPassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerResetPassword.js' /* webpackChunkName: "components/controller-reset-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerSocialLogin = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLogin.js' /* webpackChunkName: "components/controller-social-login" */).then(c => wrapFunctional(c.default || c))
export const ControllerSocialLoginButtons = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLoginButtons.js' /* webpackChunkName: "components/controller-social-login-buttons" */).then(c => wrapFunctional(c.default || c))
export const ControllerUser = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerUser.js' /* webpackChunkName: "components/controller-user" */).then(c => wrapFunctional(c.default || c))
export const ControllerWishlist = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerWishlist.js' /* webpackChunkName: "components/controller-wishlist" */).then(c => wrapFunctional(c.default || c))
export const EditorIcon = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorIcon.js' /* webpackChunkName: "components/editor-icon" */).then(c => wrapFunctional(c.default || c))
export const EditorImage = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js' /* webpackChunkName: "components/editor-image" */).then(c => wrapFunctional(c.default || c))
export const EditorImageMarkers = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorImageMarkers.js' /* webpackChunkName: "components/editor-image-markers" */).then(c => wrapFunctional(c.default || c))
export const EditorInstagramPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorInstagramPostList.js' /* webpackChunkName: "components/editor-instagram-post-list" */).then(c => wrapFunctional(c.default || c))
export const EditorLink = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorLink.js' /* webpackChunkName: "components/editor-link" */).then(c => wrapFunctional(c.default || c))
export const EditorList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorList.js' /* webpackChunkName: "components/editor-list" */).then(c => wrapFunctional(c.default || c))
export const EditorListItem = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorListItem.js' /* webpackChunkName: "components/editor-list-item" */).then(c => wrapFunctional(c.default || c))
export const EditorMap = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorMap.js' /* webpackChunkName: "components/editor-map" */).then(c => wrapFunctional(c.default || c))
export const EditorPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorPostList.js' /* webpackChunkName: "components/editor-post-list" */).then(c => wrapFunctional(c.default || c))
export const EditorProductCategoryList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorProductCategoryList.js' /* webpackChunkName: "components/editor-product-category-list" */).then(c => wrapFunctional(c.default || c))
export const EditorProductList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorProductList.js' /* webpackChunkName: "components/editor-product-list" */).then(c => wrapFunctional(c.default || c))
export const EditorTipTap = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js' /* webpackChunkName: "components/editor-tip-tap" */).then(c => wrapFunctional(c.default || c))
export const InView = () => import('../../components/inView.vue' /* webpackChunkName: "components/in-view" */).then(c => wrapFunctional(c.default || c))
export const IconsFacebook = () => import('../../components/icons/facebook.vue' /* webpackChunkName: "components/icons-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconsLeftArrow = () => import('../../components/icons/left_arrow.vue' /* webpackChunkName: "components/icons-left-arrow" */).then(c => wrapFunctional(c.default || c))
export const IconsLinkedin = () => import('../../components/icons/linkedin.vue' /* webpackChunkName: "components/icons-linkedin" */).then(c => wrapFunctional(c.default || c))
export const IconsLongArrow = () => import('../../components/icons/long_arrow.vue' /* webpackChunkName: "components/icons-long-arrow" */).then(c => wrapFunctional(c.default || c))
export const IconsMenuHamburger = () => import('../../components/icons/menuHamburger.vue' /* webpackChunkName: "components/icons-menu-hamburger" */).then(c => wrapFunctional(c.default || c))
export const IconsRightArrow = () => import('../../components/icons/right_arrow.vue' /* webpackChunkName: "components/icons-right-arrow" */).then(c => wrapFunctional(c.default || c))
export const IconsTweeter = () => import('../../components/icons/tweeter.vue' /* webpackChunkName: "components/icons-tweeter" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesCookieElement = () => import('../../components/cmsOverrides/CookieElement.vue' /* webpackChunkName: "components/cms-overrides-cookie-element" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesErrorView = () => import('../../components/cmsOverrides/ErrorView.vue' /* webpackChunkName: "components/cms-overrides-error-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesFooterElement = () => import('../../components/cmsOverrides/FooterElement.vue' /* webpackChunkName: "components/cms-overrides-footer-element" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesHeaderElement = () => import('../../components/cmsOverrides/HeaderElement.vue' /* webpackChunkName: "components/cms-overrides-header-element" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesPostCategory = () => import('../../components/cmsOverrides/PostCategory.vue' /* webpackChunkName: "components/cms-overrides-post-category" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesPostList = () => import('../../components/cmsOverrides/PostList.vue' /* webpackChunkName: "components/cms-overrides-post-list" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesPostView = () => import('../../components/cmsOverrides/PostView.vue' /* webpackChunkName: "components/cms-overrides-post-view" */).then(c => wrapFunctional(c.default || c))
export const SectionsBlogList = () => import('../../components/sections/BlogList.vue' /* webpackChunkName: "components/sections-blog-list" */).then(c => wrapFunctional(c.default || c))
export const SectionsMainTitleSection = () => import('../../components/sections/MainTitleSection.vue' /* webpackChunkName: "components/sections-main-title-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsSingleBlog = () => import('../../components/sections/SingleBlog.vue' /* webpackChunkName: "components/sections-single-blog" */).then(c => wrapFunctional(c.default || c))
export const UiBlueButton = () => import('../../components/ui/BlueButton.vue' /* webpackChunkName: "components/ui-blue-button" */).then(c => wrapFunctional(c.default || c))
export const UiTransparentButton = () => import('../../components/ui/TransparentButton.vue' /* webpackChunkName: "components/ui-transparent-button" */).then(c => wrapFunctional(c.default || c))
export const UiButtonDark = () => import('../../components/ui/buttonDark.vue' /* webpackChunkName: "components/ui-button-dark" */).then(c => wrapFunctional(c.default || c))
export const UiInput = () => import('../../components/ui/uiInput.vue' /* webpackChunkName: "components/ui-input" */).then(c => wrapFunctional(c.default || c))
export const UiTextarea = () => import('../../components/ui/uiTextarea.vue' /* webpackChunkName: "components/ui-textarea" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsTextBlock = () => import('../../components/sections/aboutUs/AboutUsTextBlock.vue' /* webpackChunkName: "components/sections-about-us-text-block" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsAwardsSection = () => import('../../components/sections/aboutUs/AwardsSection.vue' /* webpackChunkName: "components/sections-about-us-awards-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsCertificateListWithImages = () => import('../../components/sections/aboutUs/CertificateListWithImages.vue' /* webpackChunkName: "components/sections-about-us-certificate-list-with-images" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsLicencesList = () => import('../../components/sections/aboutUs/LicencesList.vue' /* webpackChunkName: "components/sections-about-us-licences-list" */).then(c => wrapFunctional(c.default || c))
export const SectionsActivitiesActivitySection = () => import('../../components/sections/activities/ActivitySection.vue' /* webpackChunkName: "components/sections-activities-activity-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsContactSection = () => import('../../components/sections/contactPage/ContactSection.vue' /* webpackChunkName: "components/sections-contact-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsEquipmentPagesReparaturniMortovi = () => import('../../components/sections/equipmentPages/reparaturniMortovi.vue' /* webpackChunkName: "components/sections-equipment-pages-reparaturni-mortovi" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageEquipmentSection = () => import('../../components/sections/homepage/EquipmentSection.vue' /* webpackChunkName: "components/sections-homepage-equipment-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageHeroSection = () => import('../../components/sections/homepage/HeroSection.vue' /* webpackChunkName: "components/sections-homepage-hero-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepagePartnerSection = () => import('../../components/sections/homepage/PartnerSection.vue' /* webpackChunkName: "components/sections-homepage-partner-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageProjectsSection = () => import('../../components/sections/homepage/ProjectsSection.vue' /* webpackChunkName: "components/sections-homepage-projects-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageSeparatedSection = () => import('../../components/sections/homepage/SeparatedSection.vue' /* webpackChunkName: "components/sections-homepage-separated-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageServicesSection = () => import('../../components/sections/homepage/ServicesSection.vue' /* webpackChunkName: "components/sections-homepage-services-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageTradeSection = () => import('../../components/sections/homepage/TradeSection.vue' /* webpackChunkName: "components/sections-homepage-trade-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsOurProjectsSection = () => import('../../components/sections/ourProjectsPage/ourProjectsSection.vue' /* webpackChunkName: "components/sections-our-projects-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsPrivacyPolicySection = () => import('../../components/sections/privacyPolicy/PrivacyPolicySection.vue' /* webpackChunkName: "components/sections-privacy-policy-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsReferenceSection = () => import('../../components/sections/reference/ReferenceSection.vue' /* webpackChunkName: "components/sections-reference-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsRehabilitatitonSevicesProjectSection = () => import('../../components/sections/rehabilitatitonSevices/ProjectSection.vue' /* webpackChunkName: "components/sections-rehabilitatiton-sevices-project-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsRehabilitatitonSevicesRehabilitationServicesSection = () => import('../../components/sections/rehabilitatitonSevices/RehabilitationServicesSection.vue' /* webpackChunkName: "components/sections-rehabilitatiton-sevices-rehabilitation-services-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsServicesGallerySection = () => import('../../components/sections/servicesGallery/ServicesGallerySection.vue' /* webpackChunkName: "components/sections-services-gallery-section" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
