import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9abea1c0 = () => interopDefault(import('./gb-cms/components/pages/AdminLogin.vue' /* webpackChunkName: "" */))
const _70a68bda = () => interopDefault(import('./gb-cms/components/pages/PageCart.js' /* webpackChunkName: "" */))
const _44b38674 = () => interopDefault(import('./gb-cms/components/pages/PageCheckout.js' /* webpackChunkName: "" */))
const _97a41c84 = () => interopDefault(import('./gb-cms/components/pages/PageConfirmEmail.js' /* webpackChunkName: "" */))
const _4133ff92 = () => interopDefault(import('./gb-cms/components/pages/PageSearch.js' /* webpackChunkName: "" */))
const _1c68a54b = () => interopDefault(import('./gb-cms/components/pages/PageLogin.js' /* webpackChunkName: "" */))
const _6407006a = () => interopDefault(import('./gb-cms/components/pages/PageProfile.js' /* webpackChunkName: "" */))
const _67e54bf7 = () => interopDefault(import('./gb-cms/components/pages/PageRegister.js' /* webpackChunkName: "" */))
const _a1746a36 = () => interopDefault(import('../node_modules/@gauss/cms-core/lib/Sitemap.js' /* webpackChunkName: "" */))
const _56efaa18 = () => interopDefault(import('./gb-cms/components/pages/PageSocialLogin.js' /* webpackChunkName: "" */))
const _5d91a0c8 = () => interopDefault(import('./gb-cms/components/pages/PageForgotPassword.js' /* webpackChunkName: "" */))
const _1d740332 = () => interopDefault(import('./gb-cms/components/pages/PageAdminCache.js' /* webpackChunkName: "" */))
const _d8cdfb62 = () => interopDefault(import('./gb-cms/components/pages/PageAdminConsent.js' /* webpackChunkName: "" */))
const _3ca95250 = () => interopDefault(import('./gb-cms/components/pages/PageAdminTemplates.js' /* webpackChunkName: "" */))
const _4c5e1d30 = () => interopDefault(import('./gb-cms/components/pages/PageCheckoutFailure.js' /* webpackChunkName: "" */))
const _3a4d5fd2 = () => interopDefault(import('./gb-cms/components/pages/PageCheckoutSuccess.js' /* webpackChunkName: "" */))
const _2ec580b8 = () => interopDefault(import('./gb-cms/components/pages/PageAdminConsentSlug.js' /* webpackChunkName: "" */))
const _4e557018 = () => interopDefault(import('./gb-cms/components/pages/PageBlog.js' /* webpackChunkName: "" */))
const _78322ec5 = () => interopDefault(import('./gb-cms/components/pages/PageProduct.js' /* webpackChunkName: "" */))
const _6a5fd778 = () => interopDefault(import('./gb-cms/components/pages/PageShop.js' /* webpackChunkName: "" */))
const _9728ef0c = () => interopDefault(import('./gb-cms/components/pages/PageEvent.js' /* webpackChunkName: "" */))
const _029bcd16 = () => interopDefault(import('./gb-cms/components/pages/PageDynamic.js' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _9abea1c0,
    name: "admin___hr"
  }, {
    path: "/adminLogin",
    component: _9abea1c0,
    name: "admin-login___hr"
  }, {
    path: "/kosarica",
    component: _70a68bda,
    name: "page-cart___hr"
  }, {
    path: "/naplata",
    component: _44b38674,
    name: "page-checkout___hr"
  }, {
    path: "/potvrdi-email",
    component: _97a41c84,
    name: "page-confirm-email___hr"
  }, {
    path: "/pretraga",
    component: _4133ff92,
    name: "page-search___hr"
  }, {
    path: "/prijava",
    component: _1c68a54b,
    name: "page-login___hr"
  }, {
    path: "/profil",
    component: _6407006a,
    name: "page-profile___hr"
  }, {
    path: "/registracija",
    component: _67e54bf7,
    name: "page-register___hr"
  }, {
    path: "/sitemap.xml",
    component: _a1746a36,
    name: "sitemap.xml___hr"
  }, {
    path: "/social-login",
    component: _56efaa18,
    name: "page-social-login___hr"
  }, {
    path: "/zaboravljena-lozinka",
    component: _5d91a0c8,
    name: "page-forgot-password___hr"
  }, {
    path: "/admin/cache",
    component: _1d740332,
    name: "admin-cache___hr"
  }, {
    path: "/admin/consent",
    component: _d8cdfb62,
    name: "admin-consent___hr"
  }, {
    path: "/admin/templates",
    component: _3ca95250,
    name: "admin-templates___hr"
  }, {
    path: "/checkout/failure",
    component: _4c5e1d30,
    name: "checkout-failure___hr"
  }, {
    path: "/de/admin",
    component: _9abea1c0,
    name: "admin___de"
  }, {
    path: "/de/adminLogin",
    component: _9abea1c0,
    name: "admin-login___de"
  }, {
    path: "/de/bestatigungs-email",
    component: _97a41c84,
    name: "page-confirm-email___de"
  }, {
    path: "/de/einkaufswagen",
    component: _70a68bda,
    name: "page-cart___de"
  }, {
    path: "/de/kasse",
    component: _44b38674,
    name: "page-checkout___de"
  }, {
    path: "/de/login",
    component: _1c68a54b,
    name: "page-login___de"
  }, {
    path: "/de/passwort-vergessen",
    component: _5d91a0c8,
    name: "page-forgot-password___de"
  }, {
    path: "/de/profil",
    component: _6407006a,
    name: "page-profile___de"
  }, {
    path: "/de/register",
    component: _67e54bf7,
    name: "page-register___de"
  }, {
    path: "/de/sitemap.xml",
    component: _a1746a36,
    name: "sitemap.xml___de"
  }, {
    path: "/de/social-login",
    component: _56efaa18,
    name: "page-social-login___de"
  }, {
    path: "/de/suche",
    component: _4133ff92,
    name: "page-search___de"
  }, {
    path: "/en/admin",
    component: _9abea1c0,
    name: "admin___en"
  }, {
    path: "/en/adminLogin",
    component: _9abea1c0,
    name: "admin-login___en"
  }, {
    path: "/en/cart",
    component: _70a68bda,
    name: "page-cart___en"
  }, {
    path: "/en/checkout",
    component: _44b38674,
    name: "page-checkout___en"
  }, {
    path: "/en/confirm-email",
    component: _97a41c84,
    name: "page-confirm-email___en"
  }, {
    path: "/en/forgot-password",
    component: _5d91a0c8,
    name: "page-forgot-password___en"
  }, {
    path: "/en/login",
    component: _1c68a54b,
    name: "page-login___en"
  }, {
    path: "/en/profile",
    component: _6407006a,
    name: "page-profile___en"
  }, {
    path: "/en/register",
    component: _67e54bf7,
    name: "page-register___en"
  }, {
    path: "/en/search",
    component: _4133ff92,
    name: "page-search___en"
  }, {
    path: "/en/sitemap.xml",
    component: _a1746a36,
    name: "sitemap.xml___en"
  }, {
    path: "/en/social-login",
    component: _56efaa18,
    name: "page-social-login___en"
  }, {
    path: "/naplata/uspjesna",
    component: _3a4d5fd2,
    name: "page-checkout-success___hr"
  }, {
    path: "/de/admin/cache",
    component: _1d740332,
    name: "admin-cache___de"
  }, {
    path: "/de/admin/consent",
    component: _d8cdfb62,
    name: "admin-consent___de"
  }, {
    path: "/de/admin/templates",
    component: _3ca95250,
    name: "admin-templates___de"
  }, {
    path: "/de/checkout/failure",
    component: _4c5e1d30,
    name: "checkout-failure___de"
  }, {
    path: "/de/checkout/success",
    component: _3a4d5fd2,
    name: "page-checkout-success___de"
  }, {
    path: "/en/admin/cache",
    component: _1d740332,
    name: "admin-cache___en"
  }, {
    path: "/en/admin/consent",
    component: _d8cdfb62,
    name: "admin-consent___en"
  }, {
    path: "/en/admin/templates",
    component: _3ca95250,
    name: "admin-templates___en"
  }, {
    path: "/en/checkout/failure",
    component: _4c5e1d30,
    name: "checkout-failure___en"
  }, {
    path: "/en/checkout/success",
    component: _3a4d5fd2,
    name: "page-checkout-success___en"
  }, {
    path: "/de/admin/consent/:slug",
    component: _2ec580b8,
    name: "admin-consent-slug___de"
  }, {
    path: "/en/admin/consent/:slug",
    component: _2ec580b8,
    name: "admin-consent-slug___en"
  }, {
    path: "/admin/consent/:slug",
    component: _2ec580b8,
    name: "admin-consent-slug___hr"
  }, {
    path: "/de/blog/:slug?",
    component: _4e557018,
    name: "page-blog___de"
  }, {
    path: "/de/produkt/:slug?",
    component: _78322ec5,
    name: "page-product___de"
  }, {
    path: "/de/shop/:slug?",
    component: _6a5fd778,
    name: "page-shop___de"
  }, {
    path: "/de/Veranstaltung/:id",
    component: _9728ef0c,
    name: "page-event___de"
  }, {
    path: "/en/blog/:slug?",
    component: _4e557018,
    name: "page-blog___en"
  }, {
    path: "/en/event/:id",
    component: _9728ef0c,
    name: "page-event___en"
  }, {
    path: "/en/product/:slug?",
    component: _78322ec5,
    name: "page-product___en"
  }, {
    path: "/en/shop/:slug?",
    component: _6a5fd778,
    name: "page-shop___en"
  }, {
    path: "/de/:slug?",
    component: _029bcd16,
    name: "page-post___de"
  }, {
    path: "/dogadjaj/:id",
    component: _9728ef0c,
    name: "page-event___hr"
  }, {
    path: "/en/:slug?",
    component: _029bcd16,
    name: "page-post___en"
  }, {
    path: "/objave/:slug?",
    component: _4e557018,
    name: "page-blog___hr"
  }, {
    path: "/product/:slug?",
    component: _78322ec5,
    name: "page-product___hr"
  }, {
    path: "/shop/:slug?",
    component: _6a5fd778,
    name: "page-shop___hr"
  }, {
    path: "/:slug?",
    component: _029bcd16,
    name: "page-post___hr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
